<template>
  <el-table class="table-medium" :size="size" stripe :data="models">
    <el-table-column prop="name" :label="$tf('name')" />
    <el-table-column :label="$tf(column)" width="100px" align="center" v-for="(column, index) in columns" :key="'el-table-column-' + column">
      <el-checkbox
        slot-scope="{ row }"
        :value="isPermissionActive(row.permissions[index])"
        @change="dispatchChangeEvent(row.permissions[index], $event)"
        v-if="row.permissions[index]"
      />
    </el-table-column>
  </el-table>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'GroupsPermissionsTable',
  props: {
    models: { type: Array, required: true },
    columns: { type: Array, required: true }
  },
  inject: {
    isPermissionActive: 'isPermissionActive'
  }
})
export default class extends Vue {
  get size() {
    const { sizes } = this.$vars;
    return sizes.table || sizes.common;
  }

  dispatchChangeEvent(permission, checked) {
    this.$emit('change', permission, checked);
  }
}
</script>
