<template>
  <div class="groups-permissions-toolbar">
    <el-button @click.stop="dispatchSelectAllEvent" v-text="$tf('select | all')" />
    <el-button @click.stop="dispatchUnselectAllEvent" v-text="$tf('cancel_v | all')" />
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';

@Component({ name: 'GroupsPermissionsToolbar' })
export default class extends Vue {
  dispatchSelectAllEvent() {
    this.$emit('selectall');
  }

  dispatchUnselectAllEvent() {
    this.$emit('unselectall');
  }
}
</script>
