<template>
  <div class="groups-permissions">
    <groups-permissions-toolbar @unselectall="handleUnselectAllEvent" @selectall="handleSelectAllEvent" />
    <groups-permissions-table :models="crudModels" :columns="crudColumns" @change="handleChangeEvent" />
    <groups-permissions-table :models="otherModels" :columns="otherColumns" @change="handleChangeEvent" />
  </div>
</template>

<script>
import { Component, Provide, Vue } from 'vue-property-decorator';
import { BuiltInCrudModels, BuiltInOtherModels, computeModelsFromPermissions, CrudActions } from './groups.helpers';
import GroupsPermissionsTable from './groups.permissions-table';
import GroupsPermissionsToolbar from './groups.permissions-toolbar';

@Component({
  name: 'GroupsPermissions',
  components: {
    GroupsPermissionsTable,
    GroupsPermissionsToolbar
  },
  props: {
    permissions: { type: Array, required: true },
    plugins: { type: Object, required: true }
  }
})
export default class extends Vue {
  crudColumns = CrudActions;
  otherColumns = ['active'];

  get permissionsSet() {
    return new Set(this.permissions);
  }

  get crudModels() {
    return BuiltInCrudModels.concat(this.pluginsModels.map(({ cruds }) => cruds).flat());
  }

  get otherModels() {
    return BuiltInOtherModels.concat(this.pluginsModels.map(({ others }) => others).flat());
  }

  get pluginsModels() {
    return Object.entries(this.plugins).map(computeModelsFromPermissions);
  }

  handleSelectAllEvent() {
    const crudPermissions = concatenateModelsPermissions(this.crudModels);
    const otherPermissions = concatenateModelsPermissions(this.otherModels);
    this.dispatchInputEvent(crudPermissions.concat(otherPermissions).filter((permission) => permission));
  }

  handleUnselectAllEvent() {
    this.dispatchInputEvent([]);
  }

  handleChangeEvent(permission, checked) {
    const permissions = checked ? [...this.permissions, permission] : this.permissions.filter((target) => target !== permission);
    this.dispatchInputEvent(permissions);
  }

  dispatchInputEvent(permissions) {
    this.$emit('input', permissions);
  }

  @Provide()
  isPermissionActive(permission) {
    return this.permissionsSet.has(permission);
  }
}

function concatenateModelsPermissions(models) {
  return models.reduce((result, { permissions }) => result.concat(permissions.filter((permission) => permission !== '')), []);
}
</script>
